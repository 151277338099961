import './styles/main.scss';

async function init() {
  // enable when vue is implemented
  // await vue();

  // A bit of webpack magic to wait running legacy code until Vue is finished,
  // but still keeping it in the same bundle for performance (legacy is not optimized).
  await import(/* webpackMode: "eager" */ './js/legacy');
}

init();
