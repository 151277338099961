import $ from 'jquery';
import tealium from '../3-utilities/tealium-util';

const moduleName = 'logo',

    selector = '.js-' + moduleName;

/**
 * Push click on logo to tealium
 */
function pushToTealium() {
    tealium.push({
        eventAction: 'logo asr vermogensbeheer',
        eventCategory: 'navigatie',
        eventLabel: window.utag_data.page_path
    });
}
/**
 * Bind tealium event
 */
function bind() {
    $(document)
        .on(
            'click',
            selector,
            pushToTealium
        );
}

bind();