/* ========================================================================== *\
IMPORTS
\* ========================================================================== */

import $ from 'jquery';
import opts from '../3-utilities/options-util';

/* == END IMPORTS =========================================================== */


/**
 * Translations object placeholder
 * @type {{}}
 */
var translations = {};

/**
 * Set explicit translations
 * @param {String|Object} specs Specifications for the translations
 * @param {String} value The value
 */
function set(specs, value) {
    translations = opts.get('translations');

    // If the request is a string
    if(typeof specs === 'string' && value !== undefined) {
        translations[specs] = value;

        return translations[specs];
    } else if(typeof specs === 'object') {
        // Merge the specs with translations
        // Defaults go in first so if there is a value present
        // it overrides the default
        $.extend(translations, specs);
    }

    return translations;
}

/**
 * Get a translation
 * @param key
 * @param defaultValue
 */
function get(key, defaultValue) {
    translations = opts.get('translations');

    // If no arguments are provided
    if(!arguments.length) {
        return translations;
    }

    // If the key and defaultValue are both set
    // and we have no translations
    if(key && defaultValue && !translations[key]) {
        return set(key, defaultValue);
    }

    return translations[key];
}

/**
 *  Parses JSON translations
 */
function parseTranslations() {
    $.extend(translations, JSON.parse($(this).html()));
}

/**
 * Initializes translations utility
 */
function init() {
    var $script = $('script[type="json/translations"]');

    if(opts.isset('translations')) {
        translations = opts.get('translations');
    }

    $script.each(parseTranslations);

    opts.set('translations', translations);
}

init();

/**
 * Exposes properties to app.options namespace
 * for naming convention
 * @type {Object}
 */
export default {
    get: get,
    set: set,
    init: init
};
