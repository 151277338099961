/**
 * Tealium utility
 *
 * Provides a structured implementation
 * of Tealium Tag manager across platforms
 */

/**
 * Reference to the Tealium Tag manager data layer
 * @type {Object}
 */
let utag,
  /**
   * Send page data with all utag links because otherwise it will be lost
   */
  pageData;

/**
 * Push to the data layer
 * @param  {Object} data The object to push
 */
function push(data) {
  if (typeof utag === 'undefined') {
    setTimeout(() => {
      push(data);
    }, 100);

    return;
  }

  if (data) {
    data.page_type = pageData.page_type;
    data.page_name = pageData.page_name;
    data.page_title = pageData.page_title;
    data.page_path = pageData.page_path;
    data.page_view_url = pageData.page_view_url;

    utag.link(data);
  }
}

/**
 * Push view to the data layer
 * @param  {Object} data The object to push
 */
function pushView(data) {
  if (typeof utag === 'undefined') {
    setTimeout(() => {
      push(data);
    }, 100);

    return;
  }

  if (data) {
    utag.view(data);
  }
}

/**
 * Get page data from utag_data and save globally
 */
function initPageData() {
  var utag_data = window.utag_data;

  pageData = {
    page_type: utag_data.page_type,
    page_name: utag_data.page_name,
    page_title: utag_data.page_title,
    page_path: utag_data.page_path,
    page_view_url: utag_data.page_view_url
  };
}

/**
 * Initialize module
 */
function init() {
  if (window.utag) {
    initPageData();
    utag = window.utag;
  } else {
    setTimeout(init, 1000);
  }
}

init();

export default {
  push,
  pushView
};
