/*
 |--------------------------------------------------------------------------
 | TEALIUM module
 |--------------------------------------------------------------------------
 */

/* ========================================================================== *\
 IMPORTS
 \* ========================================================================== */

import $ from 'jquery';
import tealium from '../3-utilities/tealium-util';
 
 /* == END IMPORTS =========================================================== */
 
 
 
 /* ========================================================================== *\
  CONSTANTS
 \* ========================================================================== */
 
 /**
  * Name of the module
  * @type {String}
  */
const moduleName = 'tealium',
    /**
     * Selector for the module
     * @type {String}
     */
    selector = '.js-' + moduleName,
    /**
     * Class for click events that trigger Tealium
     * utag.link events
     * @type {String}
     */
    clickEvent = selector + '-click-event',
    /**
     * Class for change events that trigger Tealium
     * utag.link events
     * @type {String}
     */
    changeEvent = selector + '-change-event',
    /**
     * Parent selector for deferred usage
     * @type {string}
     */
    parentSelector = 'body';
 
 /* == END CONSTANTS ========================================================= */
 
 
 /* ========================================================================== *\
  SETUP MENU
 \* ========================================================================== */
 
/**
 * pushTealiumData
 * Get data from clicked element and push to tealium
 */
function pushTealiumData() {
    let tealiumData = $(this).data();
    tealium.push(tealiumData);
}

/* == END SETUP TOOLTIP ===================================================== */


/* ========================================================================== *\
EVENT HANDLING
\* ========================================================================== */

/**
 * BindHandlers
 */
function bindHandlers() {
    $(parentSelector).on('click', clickEvent, pushTealiumData);
    $(parentSelector).on('change', changeEvent, pushTealiumData);
}
/* == END EVENT HANDLING ==================================================== */



/* ========================================================================== *\
INITIALIZATION
\* ========================================================================== */

/**
 * Initialize module
 */
function init() {
    bindHandlers();
}

init();

/* == END INITIALIZATION ==================================================== */
