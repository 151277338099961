import $ from 'jquery';
import translations from '../3-utilities/translations-util';
import adblock from '../3-utilities/adblock-util.js';
import tealium from '../3-utilities/tealium-util';

/**
 * Name of the module
 * @type {String}
 */
var moduleName = 'privacymanager',

    /**
     * Selector for the module
     * @type {String}
     */
    selector = '.js-' + moduleName,

    textSelector = selector + '-text',

    /**
     * Selector for Tealium table
     * @type {String}
     */
    tealiumTableSelector = '#__tealium_tagO2_chkBoxDiv',

    /**
     * Selector for row
     * @type {String}
     */
    rowSelector = '.tagrow',

    /**
     * Selector for category column
     * @type {String}
     */
    catColumnSelector = rowSelector + '-cat',

    /**
     * Selector for description column
     * @type {String}
     */
    descrColumnSelector = rowSelector + '-desc',

    /**
     * Selector for switch button
     * @type {String}
     */
    switchSelector = selector + ' .switch',

    /**
     * Selector for switched on/off div
     * @type {String}
     */
    switchedSelector = '.switched',

    /**
     * jQuery object of selector
     * @type {jQuery}
     */
    $el = null,

    /**
     * True if script is appended
     * @type {Boolean}
     */
    initialized = false,

    /**
     * Translations
     * @type {Object}
     */
    trans = null;

/**
 * Push switch on or off event to tagmanager
 */
function pushToTagmanager() {
    var $this = $(this),
        action = $this.children(switchedSelector).hasClass('off') ? 'uit' : 'aan',
        label = $this.parent().siblings(catColumnSelector).text();

    tealium.push({
        event: 'event',
        eventCategory: 'privacymanager',
        eventAction: action,
        eventLabel: label
    });
}

/**
 * Replace Tealium texts with translations, because Tealium is not multi language
 */
function replaceTealiumTexts() {
    const $rows = $(tealiumTableSelector).find(rowSelector);

    $rows.each((i, row) => {
        const $row = $(row),
            $descr = $row.children(descrColumnSelector),
            key = $descr.text(),
            $value = $(textSelector).filter(`[data-text-key="${key}"]`),
            value = $value.text();

        if($value.length) {
            $descr.text(value);
        }

        if(i + 1 === $rows.length && trans.tealiumDefaultCookies.length > 0) {
            $descr.text(trans.tealiumDefaultCookies);
        }
    });
}

/**
 * Move privacymanager divs in DOM to location of js-privacymanager
 */
function movePrivacymanager() {
    var $table = $(tealiumTableSelector);

    if($table.length) {
        replaceTealiumTexts();
        $el.append($table);

        return;
    }

    setTimeout(movePrivacymanager, 50);
}

/**
 * Bind events
 */
function bind() {
    $(document)
        .on(
            'click',
            switchSelector,
            pushToTagmanager
        );
}

/**
 * Check Ad Blocker
 */
function checkAdBlocker(url) {    
    adblock.areAdsBlocked(url, function a(isBlocked){
        if(isBlocked){
            var options = {};
            options.expires = 90;
            options.path = '/';
            options.domain = '.asrvermogensbeheer.nl';

            cookies('OPTOUTMULTI', '0:0|c1:1|c3:1', options);
        }
    });
}

/**
 * Setup the module
 */
function setup() {
    trans = translations.get('privacymanager');


    var src = $el.data('script-src'),
        random = Math.random(),
        script = `<script type="text/javascript" src="${src}?cb=${random}"></script>`;

    checkAdBlocker(src);

    if(!initialized) { 
        bind();

        $('head').append(script);

        initialized = true;
    } else {
        window.__tealium.load();
    }

    movePrivacymanager();
}

/**
 * Initialize the module
 */
function init() {
    /**
     * jQuery elements of the module
     * @type {jQuery}
     */
    $el = $(selector);

    if($el.length) {
        setup();
    }
}

init();