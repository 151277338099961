import $ from 'jquery'; 
import tealium from '../3-utilities/tealium-util';

/**
 * Push lang switch item click event to Tealium
 */
function pushLangSwitchClickEvent() {
	var name = $(this).text().trim();

    tealium.push({
        eventAction: 'taalswitch',
        eventCategory: 'navigatie',
        eventLabel: name
    });
}
/**
 * Push navigation item click event to Tealium
 */
function pushNavClickEvent() {
    var name = $(this).text().trim();

    tealium.push({
        eventAction: 'mijnomgeving',
        eventCategory: 'Navigatie',
        eventLabel: name
    });
}

/**
 * Push footer navigation item click event to Tealium
 */
function pushFooterNavClickEvent() {
	var name = $(this).text().trim();

    tealium.push({
        eventAction: name,
        eventCategory: 'Navigatie',
        eventLabel: document.location.pathname
    });
}

/**
 * Bind events
 */
function bind() {
    $(document).on('click', 'a.js-navigation-item', pushNavClickEvent);
    $(document).on('click', 'a.js-footernav-item', pushFooterNavClickEvent);
	$(document).on('click', 'ul.js-navigation-lang > li.lang__item:not(.lang__item--selected) a', pushLangSwitchClickEvent);
}

/**
 * Setup navigation
 */
function setup() {
    bind();
}

setup();
