/**
 * Return a boolean if an Ad Blocker is used
 * @param url an url to force to check if Ad Blockers are used
 * @returns {boolean}
 */
function areAdsBlocked(url, callback) {
  if (!url) {
    url = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js';
  }

  try {
    fetch(url, {
      method: 'HEAD',
      mode: 'no-cors'
    })
      .then(() => callback(false)) // ads are NOT blocked
      .catch(() => callback(true)); // ads are blocked
  } catch (e) {
    callback(false);
  }
}

export default {
  areAdsBlocked
};
