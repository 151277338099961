import $ from 'jquery';
import cookies from '../3-utilities/cookies-util';
"use strict";

var responsive_viewport = $(window).width(); //if you want to use responsive JS

/* ===============================
   DOM READY
   ===============================	*/
$(document).ready(function () {
  /* Cookie message - GA in use */
  if (cookies.get('hide-after-click') !== 'yes') {
    $('.l-cookie').removeClass('collapse');
  }

  $('.btn-close-cookie').on('click', function () {
    $('.l-cookie').css('display', 'none');
    cookies.set('hide-after-click', 'yes', { expires: 400 });

    return false;
  });

}); // end dom ready
