// // Make sure jquery is available in console
import './3-utilities/jquery-global';

import 'bootstrap';

// Uncomment to use mocks
window.useMock = false;

// // Modules
import './5-modules/tealium';
import './5-modules/privacymanager';
import './5-modules/logo';
import './5-modules/navigation';

import './6-platform/custom';
