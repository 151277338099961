/**
 * Return value of cookie, if not exists 'undefined'
 * @param {string} cname cookie name
 * @returns {string|undefined}
 */
function get(cname) {
  let name = cname + '=';
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return undefined;
}

/**
 * Creates a secure cookie
 * @param {string} name     Name of the cookie to set
 * @param {string} value    Value of the cookie to set
 * @param {object} options  Object with properties 'expires' (in days or 0 for session), 'path' (default '/') and 'domain'
 */
function set(name, value, options) {
  options = options || {};
  const expires = getExpiresPart(options.expires);
  const path = getPathPart(options.path);
  const domain = getDomainPart(options.domain);
  document.cookie = name + '=' + value + ';' + expires + ';secure;' + path + ';' + domain;
}

function getDomainPart(domain) {
  if ((domain || '').indexOf(document.location.hostname) === -1) {
    return '';
  }

  if (domain) {
    return 'domain=' + domain + ';';
  }
  return '';
}

function getPathPart(path) {
  if (path) {
    return 'path=' + path;
  }
  return 'path=/';
}

function getExpiresPart(expiresInDays) {
  if (expiresInDays) {
    if (expiresInDays > 400) {
      expiresInDays = 400; // Max-Age
    }
    const expiresInMilliSecs = expiresInDays * 24 * 60 * 60 * 1000;
    const d = new Date();
    d.setTime(d.getTime() + expiresInMilliSecs);
    return 'expires='+ d.toUTCString();
  }
  return 'expires=0'; // session cookie
  
}

module.exports = {
  get,
  set
};