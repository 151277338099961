/* ========================================================================== *\
IMPORTS
\* ========================================================================== */

import $ from 'jquery';
/* == END IMPORTS =========================================================== */


/**
 * The locally scoped object to set options on
 *
 * @var propHolder
 * @type {Object}
 */
var propHolder = {},
    /**
     * The locally scoped options
     *
     * @var localOptions
     * @type {Object}
     */
    localOptions = {};

/**
 * Test if a property is set
 * @param {String} property The property to check on
 */
function isset(property) {
    if(propHolder[property] !== undefined) {
        return true;
    }

    return false;
}

/**
 * Sets an options property
 *
 * @func set
 * @param {String} property The value to set
 * @param {*} [value] The value to set it to
 * @return {*} The value that is set
 */
function set(property, value) {
    if(typeof property === 'object') {
        $.extend(propHolder, property);

        return;
    }

    propHolder[property] = value;

    return propHolder[property];
}

/**
 * Gets an options property
 *
 * @func get
 * @param  {String} property The property to get
 * @param  {String} defaultValue A default value
 * @return {*} The property
 */
function get(property, defaultValue) {
    if(isset(property)) {
        return propHolder[property];
    }

    propHolder[property] = defaultValue;

    return propHolder[property];
}

/**
 * Delete an options property
 *
 * @func del
 * @param  {String} property The property to delete
 * @param  {Boolean} strictMode Whether or not to throw an exception
 */
function del(property, strictMode) {
    if(isset(property)) {
        delete propHolder[property];
    } else if(strictMode !== false) {
        throw 'Unable to delete unknown property `'
            + property
            + '` from options';
    }

    return false;
}

/**
 * Initializes the options utility
 *
 * @func init
 * @param  {Object} obj The object to initialize with
 */
function init(obj) {
    set(obj);
}

/**
 * Extend the localOptions object with the given options object
 */
function extendLocalOptions(extendOptions) {
    localOptions = $.extend(true, localOptions, extendOptions);
}

/**
 * Parse the options of a options block and add to the global options
 * @function parseOptionsBlock
 */
function parseOptionsBlock() {
    var $block = $(this),
        blockOptions = JSON.parse($block.html());

    extendLocalOptions(blockOptions);
}

/**
 * Initializes op a script tag with type="text/options"
 * Get invoked immediately!
 *
 * @func getPageOptions
 */
function getPageOptions() {
    var $script = $('script[type="json/options"]'),
        sharedSettings = {};

    extendLocalOptions(sharedSettings);

    if(!$script.length) {
        return;
    }

    $script.each(parseOptionsBlock);

    init(localOptions);
}

getPageOptions();

/**
 * Exposes properties to app.options namespace
 * for naming convention
 * @type {Object}
 */
export default {
    get,
    set,
    del,
    init,
    isset,
    getPageOptions
};
